import {Injectable} from '@angular/core'
import {environment} from '../../environments/environment'
import {map, switchMap, tap} from 'rxjs/operators'
import {HttpClient} from '@angular/common/http'
import {BehaviorSubject, Observable} from 'rxjs'
import {formatDate} from '@angular/common'
import {ILoan, ILoanAdminData} from 'loan-renewal-be'
import {MatDialog} from '@angular/material/dialog'
import {NoDataComponent} from '../common/no-data/no-data.component'

export interface LoanListItem {
  id: string // PersonNummer || OrganizationNumber || Samordningsnummer
  date: number
  loanNumber: string
  owner: string
  // Loan start date
  start: number
  // Loan end date
  end: number
  amount: number
  renewalDate: number
  selected: boolean
  responsible: string
  office: string
  alive: boolean
  frequency: string
  interest: string
  repaymentAmount: number
  repaymentDate: string
  interestDate: string
  arrangementId: number
  /**
   * O = Omsättning or V = Villkorsbilaga
   */
  type: string
  protectedIdentity: boolean
  registeredInKerne?: boolean
  newInterestDeviation?: string
  newLoanBinding?: string
  stringDate: string // This is added so that we can search on dates.
  interestDeviation: number
  ownerType: string
  /**
   * Actually one of l751, l752, l753 etc. Fix that
   */
  productCode: string
  /**
   * All dates that are not 1st day of the month should be marked as such
   */
  badDate: boolean
}

export interface LoanResponse extends ILoan {

  id: string

  timeStamp: number

  printed: LoanPrintStatus[]

}

export interface LoanPrintStatus {
  date: number
  orderedBy: string
  type: string // Think Manual/Postnord
}

export interface ConditionsUrl {
  id: string // Either uuidV4 or a combination of loanNumber_customerId.pdf
  loanNumber: string // The sparbanken loan like 5432654322	52 5146 0407
  customerId: string // Like personnummer
  url: string
  downloaded?: boolean
}

export interface PrintResponse {

  /**
   * The id of the request
   */
  id: string

  /**
   * Status, should be 'CREATED', 'MISSING' or 'COMPLETE'
   */
  status: 'CREATED' | 'MISSING' | 'COMPLETE' | 'FAILED'

  /**
   * A timed URL for the main document
   */
  url: string

  /**
   * A list of urls to "Villkorsbilagor"
   */
  conditionsUrls: ConditionsUrl[]
}

@Injectable({
  providedIn: 'root'
})
export class DataService {

  /**
   * Outsider list of loans
   */
  public loans$: BehaviorSubject<LoanListItem[]> = new BehaviorSubject<LoanListItem[]>([])

  public currentLoan$ = new BehaviorSubject<LoanResponse | null>(null)

  constructor(
    private httpClient: HttpClient,
    private dialog: MatDialog
  ) {
  }

  /**
   * Fetch all loans, these will be heavily cached
   */
  public updateLoanList(): Observable<LoanResponse[]> {
    const url = `${environment.dataServiceUrl}/loans`
    return this.httpClient.get<LoanResponse[] | undefined>(url)
      .pipe(
        map((loans: LoanResponse[] | undefined) => {
          if (!loans) {
            this.dialog.open(NoDataComponent, {disableClose: true})
          } else {
            this.loans$.next(this.processLoanData(loans))
          }
          return loans || []
        })
      )
  }

  /**
   * Fetch the details for one lone
   */
  public getLoanDetails(loanNumber: string): Observable<LoanResponse> {
    const url = `${environment.dataServiceUrl}/loans/${loanNumber}`
    return this.httpClient.get<LoanResponse>(url).pipe(
      tap(r => this.currentLoan$.next(r))
    )
  }

  /**
   * Print one or more documents
   *
   * @param ids - A list of guids to print
   */
  public print(ids: string[]): Observable<PrintResponse> {
    const url = `${environment.dataServiceUrl}/print`
    return this.httpClient.put<PrintResponse>(url, {ids, create: true})
  }

  /**
   * Get the result of the print operation
   *
   * @param id - The id of the request
   */
  public getPrint(id: string): Observable<PrintResponse> {
    const url = `${environment.dataServiceUrl}/print`
    return this.httpClient.put<PrintResponse>(url, {id})
  }

  public delete(ids: number[]): Observable<any> {
    const url = `${environment.dataServiceUrl}/loans`
    // We have to do a "request" since we are sending data with the DELETE request
    return this.httpClient.request<void>('delete', url, {body: {ids}})
      .pipe(
        switchMap(() => this.updateLoanList())
      )
  }

  public saveAdminData(loan: LoanResponse): void {
    const url = `${environment.dataServiceUrl}/loans/${loan.id}`
    this.httpClient.put<LoanResponse>(url, loan)
      .pipe()
      .subscribe({
        next: (updatedLoan: LoanResponse) => {
          this.currentLoan$.next(updatedLoan)
        }
      })
  }

  private createFakeDateString(timeStamp: number): string {
    let fullDate = formatDate(timeStamp, 'yyyy-MM-dd', 'fr')
    fullDate += fullDate.replace(/-/g, '')
    return fullDate
  }

  private processLoanData(loanResponse: LoanResponse[]): LoanListItem[] {
    return loanResponse
      .filter((lr: LoanResponse) => {
        // If Omsättningsbrev and Date is before today then do NOT include it.
        // It is seriously too late for that now. Possibly extend this by approximately
        // One month or so since the requirement is 45 days and bank uses approx. 60 days
        // notice.
        return !(!lr.changed && new Date(lr.loan.renewalDate).getTime() <= Date.now())
      })
      .filter((lr: LoanResponse) => lr.loan.currentDebt > 0)
      .map((lr: LoanResponse): LoanListItem => {
        const selected: boolean = lr.printed !== undefined
        const adminData: ILoanAdminData = lr.adminData || {}
        const renewalDate = new Date(lr.loan.actualStartDate || lr.loan.renewalDate).getTime()
        const ownerType = ['l760', 'l770', 'l765', 'l771'].indexOf(lr.product.code) !== -1 ? 'B' : 'P'
        lr.adminData = adminData
        return {
          id: lr.owner.idNumber as string,
          date: lr.timeStamp * 1000,
          loanNumber: lr.loan.loanNumber,
          owner: lr.owner.name,
          start: new Date(lr.loan.start).getTime(),
          end: new Date(lr.loan.end).getTime(),
          renewalDate,
          amount: lr.loan.currentDebt,
          responsible: lr.owner.responsiblePersonName || 'Saknas',
          office: lr.owner.office || 'Saknas',
          alive: lr.owner.deceased,
          frequency: (lr.product.frequency || '').replace('månader', 'må'),
          selected,
          interest: lr.loan.interest,
          repaymentDate: lr.loan.repaymentDate,
          repaymentAmount: lr.loan.repaymentAmount,
          interestDate: lr.loan.interestDate,
          arrangementId: lr.loan.arrangementId,
          type: lr.changed ? 'V' : 'O',
          protectedIdentity: lr.owner.protectedIdentity as boolean,
          // The registered in MP part kan be removed when moving from test
          registeredInKerne: adminData.registeredInKerne,
          newInterestDeviation: adminData.newInterestDeviation,
          newLoanBinding: adminData.newLoanBinding,
          stringDate: this.createFakeDateString(renewalDate),
          interestDeviation: lr.product.interestDeviation,
          ownerType,
          badDate: lr.product.code !== 'Borgo' && new Date(lr.loan.renewalDate).getDate() !== 1,
          productCode: lr.product.code
        }
      })
  }
}
